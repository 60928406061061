import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GooglePlay from "../assets/GooglePlay.jpeg";
import AppStore from "../assets/AppStore.jpeg";
import Dashboard from "../assets/Dashboard.png";
// import { useTheme } from '@mui/system';

const logoStyle = {
  minWidth: "275px",
  maxWidth: "275px",
  height: "auto",
  margin: "0 32px",
};

const Logos = [GooglePlay, AppStore, Dashboard];
const CTALogos = [
  {
    icon: GooglePlay,
    link: "https://play.google.com/store/apps/details?id=com.clinic.arogriha",
  },
  {
    icon: AppStore,
    link: "",
  },
  {
    icon: Dashboard,
    link: "",
  },
];

export default function LogoCollection() {
  return (
    <Box id="logoCollection" sx={{ pt: 12, pb: 12, backgroundColor: "black" }}>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        sx={{ mt: 0.5 }}
      >
        {CTALogos.map((logo, index) => (
          <Grid
            item
            key={index}
            sx={{
              mt: { xs: 4, md: 0 },
              display: { xs: index === 2 ? "none" : "flex", sm: "flex" },
              cursor: logo.link ? "pointer" : "default",
            }}
            onClick={() => {
              if (logo.link) {
                window.open(logo.link, "_blank"); // Opens the link in a new tab
              } else {
                console.log("No link available");
              }
            }}
          >
            <img
              src={logo.icon}
              alt={`number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
