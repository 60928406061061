import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import HighlightGradient from './HighlightGradient'; // Import the HighlightGradient component

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Adaptive Functionality',
    description:
      'With intelligent automation and customizable settings, it simplifies operations and enhances productivity.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Top-tier Technology',
    description:
      'Engineered with top-tier technology to ensure reliable, long-term performance.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Great User Experience',
    description:
      'Navigate with ease through our user-friendly interface.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Feature Innovative',
    description:
      'Stay at the forefront of telemedicine with cutting-edge features like AI-assisted diagnosis.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reliable Support',
    description:
      'Rely on our dedicated support team for a smooth experience.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Precision Engineered',
    description:
      'Offerings refined for accuracy and ease.',
  },
];


export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our solution stands out: adaptability, durability,
            user-friendly design, and innovation.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  backgroundColor: 'grey.900',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {/* Use the HighlightGradient Component */}
                <HighlightGradient />

                {/* Card Content */}
                <div style={{position: "relative"}}>
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
