import "./App.css";
import Landing from "./LandingPage";
import MeshGradient from "./components/MeshGradient";
// import ShadowGradientBox from './components/ShadowGradientBox';
// import Glassmorphism from "./components/GlassMorphism";
// import GlassmorphismCard from "./components/GlassmorphismCard";

function App() {
  return (
    <Landing />
  );
}

export default App;
